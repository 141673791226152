import { Box, Button, CardMedia, Container, Grid, Typography } from '@mui/material'
import React from 'react'

import drivingLesson from "../../assets/images/driving-lesson/deriving-lesson-bg.png"
import tick from "../../assets/images/driving-lesson/tick.png"

import Register from '../Register/Register'
import { useState } from 'react'

import dlRightVideo from "../../assets/videos/DLrht.mp4"
import imgg from "../../assets/combopageimage.png"
const styles = {
  bannerRight: {
    '@media (max-width: 900px)': {
      paddingTop: '30px',
    }
  },
  dlBanner: {
    flexGrow: 1, background: `url(${drivingLesson}) no-repeat top center`, backgroundSize: '100% 100%', padding: '150px 0px 80px',
    '@media (max-width: 900px)': {
      padding: '50px 0px 50px'
    }
  },
  subtittle1: {
    fontSize: "22px !important", fontWeight: '400 !important', lineHeight: '1.5', color: '#fff !important',
  },
  subtittle2: {
    color: 'var(--yellow) !important', marginTop: '10px',
  }
}




const DlBanner = () => {

  const [registerStatus, setRegisterStatus] = useState(false)

  return (
    <>

      <Box sx={styles.dlBanner} >

        <Container>
          <Grid container spacing={2} sx={{ alignItems: 'center' }}>

            <Grid item md={7} >
              {/* <Typography component='subtittle1' sx={styles.subtittle1}>Drive with Confidence: Expert Lessons to Keep You Ahead!</Typography> */}
              <Typography variant='h1' sx={styles.subtittle2}>"Get Ahead of the Curve, Driving <span >Classes"</span> </Typography>
              <Grid sx={{ margin: '30px 0px 0px', }}>
                <Grid sx={{ display: 'flex', marginBottom: '20px', }}><img src={tick} style={{ width: '20px', height: '20px', margin: '6px 6px 0px 0px' }} /> &nbsp; <Typography color="#fff !important"><Typography component="span1" color="#fff !important" >Empowering you to become a Safe, Confident, and Responsible Driver</Typography></Typography></Grid>
                <Grid sx={{ display: 'flex', marginBottom: '20px', }}><img src={tick} style={{ width: '20px', height: '20px', margin: '6px 6px 0px 0px' }} /> &nbsp; <Typography color="#fff !important"><Typography component="span1" color="#fff !important" >We Don't Just Teach Driving, We Build Confident Drivers!</Typography></Typography></Grid>
              </Grid>

              <Button variant="outlined" className='greenButAni' onClick={() => window.scrollTo(0, 500)}>
                REGISTER NOW
              </Button>

              {/* <Register openStatus={registerStatus} setRegisterStatus={setRegisterStatus}/> */}
            </Grid>

            <Grid item md={5}>
              <CardMedia className="" component="img" alt="DD Zoom" src={imgg} sx={styles.bannerRight} />
              {/* <CardMedia component="video" autoPlay loop muted src={dlRightVideo}  sx={styles.bannerRight} /> */}
            </Grid>

          </Grid>
        </Container>

      </Box>




    </>
  )
}

export default DlBanner;
