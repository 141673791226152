import { Box, CardMedia, Container, Grid, Typography } from '@mui/material'
import React from 'react'

import ProminentBg from '../../assets/images/driving-lesson/drvng_lessons_prominent_bg.png';
import DloFeatureImg from '../../assets/images/driving-lesson/DloFeatureImg.png';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import pickup from '../../assets/images/driving-lesson/pickup.gif';

const styles = {
    featureBg: {
        flexGrow: 1, background: `url(${ProminentBg}) no-repeat top center`, backgroundSize: '100% 100%', padding: '60px 0px 60px',
        '@media (max-width: 900px)': {
            padding: '50px 0px 50px 16px',
        }
    },
    featureGif: {
        width: '70px', height: '70px',
    },
    featureBoxCon: {
        background: '#D7E7FF', display: 'flex', gap: '10px', padding: '24px 20px', borderRadius: '6px',
        '&:hover': { background: '#fff', },
        '@media (max-width: 600px)': {
            flexDirection: 'column',
        },

    },
}

const featureBox = [
    {
        featureBoxGif: pickup,
        tittle: "Interactive Learning",
        description: "The Safe2Drive platform is fun-packed with interactive games, animations, videos, and more to make your learning experience engaging.",

    },
    {
        featureBoxGif: pickup,
        tittle: "24/7 Access",
        description: "Learn at your own pace from anywhere at anytime. Our platform is available round-the-clock facilitating easy log in and log out.",

    },
    {
        featureBoxGif: pickup,
        tittle: "Progress Tracking",
        description: "With the user-friendly interface of Safe2Drive platform, monitor your course progress and know where you are",

    },
]


const DloFeatures = () => {
    return (
        <>

            <Box sx={styles.featureBg} >
                <Container >
                    <Grid container spacing={2}>

                        <Grid item md={6}>
                            <Typography sx={{ color: '#fff !important', marginBottom: '10px', }}>Features</Typography>
                            <Typography variant='h4' sx={{ color: 'var(--yellow) !important', }}>Significant Aspects of the Course</Typography>
                            <Typography variant='h4' sx={{ color: '#fff !important', mt: '10px', }}>Interactive Learning Experience</Typography>
                        </Grid>

                        <Grid item md={6} sx={{ display: 'flex', justifyContent: 'end', }}>
                            <Grid sx={{ position: 'relative' }}>
                                {/* <img src={DloFeatureImg} style={{maxWidth:'100%',}}/>
                        <PlayArrowIcon className='playbut'  sx={{position:'absolute',}} /> */}

                                <video controls width="90%" height={'90%'}>
                                    <source src={"https://webnox.blr1.digitaloceanspaces.com/1ACT_WEBSITE_VIDEOS/Joshua%20law.mp4"}
                                        type="video/mp4" />
                                    Sorry, your browser doesn't support embedded videos.
                                </video>
                            </Grid>
                        </Grid>


                    </Grid>

                    <Grid container spacing={2} sx={{ marginTop: '40px', display: 'flex', gap: '14px', }}>

                        {featureBox.map((featureBoxItem, index) => (
                            <Grid item md={3.9} key={index} sx={{ padding: '0px !important', display: 'grid' }}>
                                <Grid sx={styles.featureBoxCon}>

                                    <CardMedia component="img" alt="program Image" src={featureBoxItem.featureBoxGif} sx={styles.featureGif} />
                                    <Grid>
                                        <Typography variant="h2" sx={{ marginBottom: '10px' }}>{featureBoxItem.tittle}</Typography>
                                        <Typography component="f15w500">{featureBoxItem.description}</Typography>
                                    </Grid>

                                </Grid>
                            </Grid>
                        ))}

                    </Grid>

                </Container>
            </Box>


        </>
    )
}

export default DloFeatures
