import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from '../pages/Home';
import DrivingLesson from '../pages/Drivinglessons';
import RoadTesting from '../pages/RoadTesting';
import DefensiveDrivingLocation from '../pages/DefensiveDrivingLocation';
import ClinicalEvaluation from '../pages/ClinicalEvaluation';
import DuiRiskReductionLocation from '../pages/DuiRiskReductionLocation';
import Locations from '../pages/Locations';
import DrivingLessonJoshuaLaw from '../pages/DrivingLessonJoshuaLaw';
import DrivingLessonOnline from '../pages/DrivingLessonOnline';
import DefensiveDrivingRemote from '../pages/DefensiveDrivingRemote';
import DuiRiskReductionZoom from '../pages/DuiRiskReductionZoom';
import ExamplePage from '../pages/ExamplePage';
import Login from '../pages/Login';
import Dashboard from '../pages/DashBoard/Dashboard';
import SuccessPage from '../pages/Register/SuccessPage';
import CancellationPolicy from '../pages/CancellationPolicy';
import GDECScholarship from '../pages/GDECScholarship';
import JoshuaLaw from '../pages/JoshuaLaw';
import VictimImpact from '../pages/VictimImpact';
import MyCart from '../pages/AddtoCart/MyCart';
import MyProfile from '../pages/Profile/MyProfile';
import InsurenceIndex from '../pages/InsurenceReduction/Index';

// Scroll to top component

const ScrollToTop = () => {

  useEffect(() => {
    const onRouteChange = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener('popstate', onRouteChange);

    return () => {
      window.removeEventListener('popstate', onRouteChange);
    };
  }, []);

  return null;
};

const MainRoutes = () => {
  return (
    <Router>

      <Routes>

        <Route path="/" element={<Home />} />
        <Route path="/driving-lesson/:id" element={<DrivingLesson />} />
        <Route path="/driving-lesson-joshuas-law" element={<DrivingLessonJoshuaLaw />} />
        <Route path="/driving-lesson-online" element={<DrivingLessonOnline />} />
        <Route path="/road-testing" element={<RoadTesting />} />
        <Route path="/defensive-driving-location" element={<DefensiveDrivingLocation />} />
        <Route path="/defensive-driving-remote/:id" element={<DefensiveDrivingRemote />} />
        <Route path="/dui-risk-reduction-location" element={<DuiRiskReductionLocation />} />
        <Route path="/dui-risk-reduction-zoom/:id" element={<DuiRiskReductionZoom />} />
        <Route path="/clinical-evaluation" element={<ClinicalEvaluation />} />
        <Route path="/location" element={<Locations />} />
        <Route path="/cancellation-privacy-policy" element={<CancellationPolicy />} />
        {/* <Route path="/login" element={<Login />} /> */}
        <Route path="/example" element={<ExamplePage />} />
        <Route path="/checkout" element={<SuccessPage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/gdec-scholarship" element={<GDECScholarship />} />
        <Route path="/joshua's-law-info" element={<JoshuaLaw />} />
        <Route path="/victim-impacts" element={<VictimImpact />} />
        <Route path="/cart" element={<MyCart />} />
        <Route path="/myprofile" element={<MyProfile />} />

        <Route path="/insurance-reduction" element={<InsurenceIndex/>} />
      </Routes>
    </Router>
  );
};

export default MainRoutes;
