

import React from 'react'
import { Box, Button, CardMedia, Container, Grid, Typography, Stack } from '@mui/material'

import footban2 from "../../assets/images/home/footban2.jpg"
import car from "../../assets/car.webp"
import { useNavigate } from 'react-router-dom'
const styles = {

    CancellationPolicyHeder: {
        background: `url(${footban2}) no-repeat top center`, backgroundSize: '100% 100%', padding: '160px 0px 60px',
        '@media (max-width: 900px)': {
            padding: '50px 0px 50px',
        }
    },

    cancellationHeading: {
        color: 'var(--primary) !important',
        fontSize: '22px !important'
    },

    CancellationPolicyCon: {
        display: 'flex', flexDirection: 'column', gap: '14px', mt: '40px',
    }


}


const InsurenceReduction = () => {

    const navigate = useNavigate();

    const handleNavigation = () => {
        navigate('/defensive-driving-remote/1'); // Replace '/policy' with your actual policy route
    };
    return (
        <>

            <Box>


                <Box sx={styles.CancellationPolicyHeder}>
                    <Container>
                        <Typography variant='h4' sx={{ color: '#fff !important', }}>INSURANCE  REDUCTION</Typography>
                    </Container>
                </Box>

                <Box sx={{ padding: '40px 0px' }}>
                    <Container>
                        <Grid>

                            <Typography variant='h2' marginBottom={2} sx={styles.cancellationHeading}>DDS CERTIFIED 3-YEAR INSURANCE PREMIUM REDUCTION CLASS</Typography>
                            <Typography variant='p' sx={{ fontSize: '20px !important' }}> <b>Did you know ?</b></Typography>


                        </Grid>

                        <Grid container spacing={4} sx={{ mt: 2 }}>

                            <Grid item xs={12} md={7}>
                                <Stack spacing={4}>


                                    <Typography variant='p'>
                                        Taking a 6-hour defensive driving class could give you a minimum of a 10% discount on your insurance premium!
                                    </Typography>

                                    <Typography variant='p' >
                                        Individuals 25 years of age or older, who have a clean driving record for the previous three years and take the six-hour program shall be eligible, pursuant to O.C.G.A. 33-9-42, to obtain not less than a 10% discount on their automobile insurance premium. This discount is valid for the next three years. Often couples, who are the two named drivers on an insurance policy, will take this program together to qualify for the joint insurance discount. Please also call your insurance agent to verify.
                                    </Typography>


                                    <Typography variant='p' >
                                        We work with many private companies, both in Atlanta and surrounding areas. Most companies have a requirement that their employees, who operate a company vehicle or their own vehicle on behalf of their company, take this course once a year or once every three years to meet company policy and be eligible for lower insurance rates.
                                    </Typography>

                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Stack spacing={2}>
                                    <img src={car} alt='tets' />
                                    {/* <Typography variant='p' sx={{ fontSize: '20px !important',textAlign:'center' }}> <b>We can help lower your insurance!</b></Typography> */}
                                </Stack>

                            </Grid>

                            <Grid item xs={12} sx={{ mt: 2 }}>
                                <Typography variant='p' >
                                    You’ll have the discount for 3 years, and after those 3 years, you can take the class and get the discount all over again! Call your insurance company to find out what the discount will be and to ensure that you will get the discount for taking the class.<span onClick={handleNavigation} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                                    >Click here to register.</span>
                                </Typography>
                            </Grid>
                        </Grid>

                    </Container>
                </Box>

            </Box>

        </>
    )
}

export default InsurenceReduction
