import { Box, CardMedia, Container, Grid, Typography, Stack,Divider } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import ProminentBg from '../../assets/images/driving-lesson/drvng_lessons_prominent_bg.png'
import ProminentRhtImg from '../../assets/images/driving-lesson/Prominent-rht-img.png'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import pickup from '../../assets/images/driving-lesson/pickup.gif'
import { keyframes } from '@mui/system';
const styles = {
    featureBg: {
        flexGrow: 1, background: `url(${ProminentBg}) no-repeat top center`, backgroundSize: '100% 100%', padding: '60px 0px 60px',
        '@media (max-width: 900px)': {
            padding: '50px 0px 50px 16px',
        }
    },
    featureGif: {
        width: '70px', height: '70px',
    },
    featureBoxCon: {
        background: '#D7E7FF', display: 'flex', gap: '10px', padding: '24px 20px', borderRadius: '6px',
        '&:hover': { background: '#fff', },
        '@media (max-width: 600px)': {
            flexDirection: 'column',
        },

    },
}


const featureBox = [
    {
        featureBoxGif: pickup,
        tittle: "Pickup and drop",
        description: "Flexible online scheduling and free Pickup and Drop off within our radius!",

    },
    {
        featureBoxGif: pickup,
        tittle: "Expert Instruction",
        description: "Learn from licensed professionals with years of experience, ensuring Top-notch Driving Lessons and safety",

    },
    {
        featureBoxGif: pickup,
        tittle: "Service Radius",
        description: "Our Service Area covers a 15-mile Radius Beyond each of our convenient locations",

    },
]


const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;


const Prominent = () => {

    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    // Intersection Observer to trigger animation when the section comes into view
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                setIsVisible(entry.isIntersecting);
            },
            { threshold: 0.1 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);
    return (
        <>
            <Box sx={styles.featureBg} >
                <Container>
                    <Grid container spacing={4} alignItems={'center'}>

                        <Grid item md={6}>
                            {/* <Typography sx={{ color: '#fff !important', marginBottom: '10px', }}>Features</Typography> */}
                            {/* <Typography variant='h4' sx={{ color: 'var(--yellow) !important', }}>Prominent Characteristic Features</Typography> */}
                            <Stack spacing={3} ref={sectionRef} alignItems="center">
                      
                                <Typography
                                    variant="h1"
                                    sx={{
                                        letterSpacing: '5px !important',
                                        fontSize: '2.5rem !important',
                                        background: '#d32f2f',
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                                        animation: isVisible ? `${fadeIn} 1.5s ease-in-out` : 'none',
                                        transition: 'transform 0.3s ease',
                                        '&:hover': {
                                            transform: 'scale(1.1)',
                                        },
                                    }}
                                >
                                    LEARN
                                </Typography>

                                {/* <Divider sx={{ width: '30%', backgroundColor: '#d32f2f', height: '2px' }} /> */}
                                <Typography
                                    variant="h1"
                                    sx={{
                                        letterSpacing: '7px !important',
                                        fontSize: '3.3rem !important',
                                        background: '#FFB74D',
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        textShadow: '2px 2px 5px rgba(0, 0, 0, 0.6)',
                                        animation: isVisible ? `${fadeIn} 2s ease-in-out` : 'none',
                                        transition: 'transform 0.3s ease',
                                        '&:hover': {
                                            transform: 'scale(1.15)',
                                        },
                                    }}
                                >
                                    DRIVE
                                </Typography>

                            
                                <Typography
                                    variant="h1"
                                    sx={{
                                        letterSpacing: '10px !important',
                                        fontSize: '3.5rem !important',
                                        background: '#8BC34A',
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        textShadow: '3px 3px 6px rgba(0, 0, 0, 0.7)',
                                        animation: isVisible ? `${fadeIn} 2.5s ease-in-out` : 'none',
                                        transition: 'transform 0.3s ease',
                                        '&:hover': {
                                            transform: 'scale(1.2)',
                                        },
                                    }}
                                >
                                    SUCCEED
                                </Typography>
                            </Stack>
                        </Grid>

                        <Grid item md={6} sx={{ display: 'flex', justifyContent: 'end', }}>
                            <Grid sx={{ position: 'relative' }}>

                                <video controls width="96%" height={'96%'}>
                                    <source src={"https://webnox.blr1.digitaloceanspaces.com/1ACT_WEBSITE_VIDEOS/Behind%20The%20Wheel_Final%20(2).mp4"}
                                        type="video/mp4" />
                                    Sorry, your browser doesn't support embedded videos.
                                </video>
                                {/* <PlayArrowIcon className='playbut' sx={{ position: 'absolute', }} onClick={() => console.log('dddd')} /> */}

                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid container spacing={2} sx={{ marginTop: '40px', display: 'flex', gap: '14px', }}>

                        {featureBox.map((featureBoxItem, index) => (
                            <Grid item md={3.9} key={index} sx={{ padding: '0px !important', display: 'grid' }}>
                                <Grid sx={styles.featureBoxCon}>

                                    <CardMedia component="img" alt="program Image" src={featureBoxItem.featureBoxGif} sx={styles.featureGif} />
                                    <Grid>
                                        <Typography variant="h2" sx={{ marginBottom: '10px' }}>{featureBoxItem.tittle}</Typography>
                                        <Typography component="f15w500">{featureBoxItem.description}</Typography>
                                    </Grid>

                                </Grid>
                            </Grid>
                        ))}

                    </Grid>

                </Container>
            </Box>

        </>
    )
}

export default Prominent
