import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import DlBanner from './DlBanner'
import Prominent from './Prominent'
import BehindTheWheel from './BehindTheWheel'
import DrivingLessonsFaq from './DrivingLessonsFaq'
import DlPackages from './DlPackages'
import { useParams } from 'react-router-dom'
import OfferGreenBanner from './OfferGreenBanner'


const DrivingLesson = () => {

  const { id } = useParams()
  const [initialUpdate, setInitialUpdate] = useState(true)
  useEffect(() => {
    if (initialUpdate) {
      if (id == 2) {
        window.scrollTo(0, 500);
      } else {
        window.scrollTo(0, 0);
      }
      setInitialUpdate(false)
    }


  }, [])
  return (
    <>
      <Header />
      <DlBanner />
      <DlPackages />
      <OfferGreenBanner />
      <Prominent />
      <BehindTheWheel />
      <DrivingLessonsFaq />
      <Footer />
    </>
  )
}

export default DrivingLesson
