import React from "react";
import { Box, CardMedia, Container, Grid, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import DljlFaqImg from "../../assets/images/driving-lesson/DljlFaqImg.png";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';


const styles = {

  faqRight: {

    '@media (max-width: 900px)': {
      margin: 'auto',

    },
  },
  faqRightImage: {
    width: '80%', marginRight: 'auto', marginY: '30px',
    '@media (max-width: 900px)': {
      width: '100%'
    }
  }
}


const drivingLessonsFaq = [
  {
    question: "What is Joshua's Law, and how does it impact driver's education requirements?",
    answer: "Senate Bill 226, also known as “Joshua’s Law,” was passed during the 2005 General Assembly. Beginning January 1, 2007, all 16- and 17-year-olds applying for a Class D driver’s license must complete an approved 30 hour driver’s education course as well as at least 6 hours with a Georgia Department of Driver Services certified driving school.",
  },
  {
    question: "What are the benefits of enrolling in a DDS - approved Driver’s education course?",
    answer: 'The class is being taught by a well experienced certified driving instructor who goes over all material and is available to answer any questions or doubts.',
  },
  {
    question: "What topics are covered in the Driver’s education course offered by 1 ACT Driving schools?",
    answer: ' We teach the curriculum offered by AAA called “How to Drive” The material takes a comprehensive approach to reduced-risk driving and introduces new drivers and their families to The Highway Transportation System, best driving practices, rules of the road, and modern-day vehicle safety technology systems.',
  },
  {
    question: "How can parents monitor students under 18 during the course?",
    answer: 'Parents may access the zoom link provided to check up on their child or they can stay up to date with the instructor’s attendance on the student/parent portal.',
  },

]


const DljlFaq = () => {
  return (
    <>

      <Container>

        <Box sx={{ pt: "50px", }}>

          <Grid sx={{ border: '1px solid var(--primary)', padding: '20px 40px', borderRadius: '10px', background: 'var(--lightblue)' }}>
            <Typography variant='h6' sx={{ fontWeight: '500 !important', }}>
              <b>*30-HR Driver’s Education Class DOES NOT INCLUDE the ADAP (Alcohol and Drug Awareness Program) certification.</b>
              <span style={{ fontWeight: '500 !important' }}>You can get the ADAP certification by checking with the student’s high school to see if they completed it there or visit DDS to complete it <a href="https://dds.georgia.gov/adap">https://dds.georgia.gov/adap*</a></span>
            </Typography>
          </Grid>

        </Box>

        <Box sx={{ py: "50px", }}>

          <Grid sx={{ border: '1px solid var(--primary)', padding: '20px 40px', borderRadius: '10px', background: 'var(--lightblue)' }}>
            <Typography p="10px 0px 20px" variant='h6'>Enrolling your child in a certified driver's training course can be a great MONEY-SAVING move!</Typography>

            <Typography variant='h6' sx={{ display: 'flex', alignItems: 'baseline', p: '10px 0px 0px' }}><FiberManualRecordIcon sx={{ fontSize: '10px', color: 'var(--primary)' }} />&nbsp; Discount on Insurance Premium</Typography>
            <Typography component='f14'> Completing a certified driver's education program can reduce your car insurance premium by at least 10% as stated by OCGA 33-9-42.</Typography>
            <Typography variant='h6' sx={{ display: 'flex', alignItems: 'baseline', p: '10px 0px 0px' }}><FiberManualRecordIcon sx={{ fontSize: '10px', color: 'var(--primary)' }} />&nbsp; Tax Credit</Typography>
            <Typography component='f14'>According to OC.G.A. 48-7-29.5, enrolling your child in a driver's education course offered by a licensed private company like 1 ACT qualifies you for a tax credit of the amount paid for the course or $150 on your Georgia state income taxes.</Typography>
          </Grid>

        </Box>


        <Box sx={{ py: "50px", position: "relative", }}>
          <div className="DrivingLessonsFaq_ani"></div>
          <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', }}>
            <Grid item md={6} sx={styles.faqRight}>
              <Typography variant="h4" sx={{ pt: 2, pb: 2 }}>
                Frequently Asked Question
              </Typography>

              <Grid data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                <CardMedia className="hmeslidelft " component="img" alt="faq Image" src={DljlFaqImg} sx={styles.faqRightImage} />
              </Grid>

            </Grid>

            <Grid item md={6}>
              <div>

                {drivingLessonsFaq.map((drivingLessonsFaq, index) => (

                  <Accordion key={index} defaultExpanded={index === 0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography variant="h6">
                        {drivingLessonsFaq.question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{ fontSize: '15px !important', }} dangerouslySetInnerHTML={{ __html: drivingLessonsFaq.answer.replace(/<br\s*\/?>/mg, "<br/>", "'", ".", "") }}></Typography>

                    </AccordionDetails>
                  </Accordion>
                ))}

              </div>
            </Grid>

          </Grid>

        </Box>

      </Container>
    </>
  )
}

export default DljlFaq;
