import { Box, Container, Grid, Typography, Button, Stack, Card, CardContent, CardMedia, Divider, DialogActions } from "@mui/material";
import "react-tabs/style/react-tabs.css";
import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Register from "../Register/Register";
import { useEffect, useState } from "react";
import axios from "axios";
import { base_Url } from "../BaseUrl/ApiUrl";
import { Toaster, toast } from "react-hot-toast";
import _ from 'lodash'
import { useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";


import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";
import UpsellingDialog from "./UpsellingDialog";

const styles = {
  tabGridItems: {
    // display: "grid",
    // gridTemplateColumns: "repeat(2, 1fr)",
    // alignItems: "baseline",
    // "@media (max-width: 600px)": {
    //   gridTemplateColumns: "unset",
    // },
    marginBottom: '30px'
  },
  dotIcon: {
    color: "var(--primary)",
    fontSize: "12px",
  },
  tabAmount: {
    color: "#000",
    marginRight: "20px",
  },
  tabGreenBox: {
    background: "var(--green)",
    color: "#fff",
    fontSize: "12px",
    padding: "0px 0px",
    borderRadius: "5px",
  },
  behindTheWheelYellowBox: {
    background: "#FFED97",
    fontSize: "13px !important",
    width: "fit-content",
    marginLeft: "auto",
    borderRadius: "4px",
    padding: "0px 10px !important",
  },
  behindTheWheelBox: {
    background: "#fff",
    border: "1px solid #DADADA",
    borderRadius: "10px",
  },
  packagesBox: {
    boxShadow: "3px 3px 10px 5px #00000012",
    borderTop: "2px solid var(--primary)",
    padding: "20px",
  },
  packagesBoxAttributes: {
    mt: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "14px",
  },
  packagesBoxAttributeItems: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  packagesBoxButton: {
    mt: "20px",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "4px",
  },
  tabMainBox: {
    background: "#F4F8FC",
    padding: "30px",
    paddingLeft: "30px !important",
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  chooseCombo: {
    background: "#E8EFF6",
    padding: "20px !important",
    "@media (max-width: 600px)": {
      width: "100%",
      marginTop: "30px",
    },
  },
  buynowBlueButton: {
    border: "1px solid var(--primary)",
    color: "#fff",
    padding: "10px 16px",
    background: "var(--primary)",
    "&:hover": { color: "var(--primary)", background: "#fff" },
  },
  scroolbarLocation: {
    height: "386px",
    overflow: " scroll",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    // "&::-webkit-scrollbar": {
    //   display: "none",
    // },
    "@media (max-width: 900px)": {
      height: "890px",
    },
  },

};





function DlPackages() {

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

  const navigate = useNavigate()
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedBehindCourse, setSelectedBehindCourse] = useState(null);

  const [selectedBTWHour, setSelectedBTWHour] = useState(null)

  console.log('selectedBehindCourse >>>', selectedBehindCourse)


  console.log('selectedBTWHour >>>', selectedBTWHour)

  const handleTabClick = (item) => {
    console.log('item >>>>?????????', item)


    setSelectedBehindCourse((prevSelected) =>
      prevSelected === item.packageid ? null : item.packageid
    );

    setSelectedBTWHour((prevSelected) =>
      prevSelected === item.duration ? null : item.duration
    );


  };




  const [BehindTheWheel, setBehindTheWheel] = useState([])
  const [Dui, setDui] = useState([])
  const [defensive, setDefensive] = useState([])
  const [driversEducation, setDriversEducation] = useState([])
  const [roadTesting, setRoadTesting] = useState([])
  const [BtwRoadtest, setBtwRoadTest] = useState([])

  console.log('roadTesting >>>', roadTesting)




  // useEffect(() => {
  //   let price;
  //   if (!_.isNull(selectedBehindCourse)) {
  //     price = roadTesting.find((item) => {
  //       return item.packageamount == 125
  //     })
  //   } else {
  //     price = roadTesting.find((item) => {
  //       return item.packageamount !== 125
  //     })
  //   }
  //   console.log('price >>', price)
  // }, [selectedBehindCourse])





  const fetchPackages = async () => {
    try {
      const response = await axios.post(`${base_Url}/getAllCourse`, {
        type: ''
      })
      if (response.data.message === "success") {
        console.log('response', response?.data?.response)
        const course1 = response?.data?.response[0]?.courseDetails
        setBehindTheWheel(course1)
        setSelectedBehindCourse(course1[0]?.packageid)
        setSelectedBTWHour(course1[0]?.duration)

        const course2 = response?.data?.response[1]?.courseDetails
        setDui(course2)

        const course3 = response?.data?.response[2]?.courseDetails
        setDefensive(course3)

        const course4 = response?.data?.response[3]?.courseDetails
        setDriversEducation(course4)

        const course5 = response?.data?.response[4]?.courseDetails



        // let lowerPackage = [];
        // let higherPackage = [];

        // if (course5?.length === 2) {
        //   if (course5[0].packageamount < course5[1].packageamount) {
        //     lowerPackage.push(course5[0]);
        //     higherPackage.push(course5[1]);
        //   } else {
        //     lowerPackage.push(course5[1]);
        //     higherPackage.push(course5[0]);
        //   }
        // }
        // setBtwRoadTest(lowerPackage)
        setRoadTesting(course5)
        // console.log("kavin", lowerPackage);
        // console.log("Higher Package:", higherPackage);
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchPackages()
  }, [])



  const [productTypeCount, setProductTypeCount] = useState({});
  const handleSelect = (item) => {
    const isSelected = selectedCourses.includes(item.packageid);
    const currentProductTypeCount = { ...productTypeCount };

    if (isSelected) {
      // If item is already selected, we need to remove it
      setSelectedCourses(selectedCourses.filter(packageid => packageid !== item.packageid));
      // Decrease the count of the product type
      currentProductTypeCount[item.producttype] = (currentProductTypeCount[item.producttype] || 1) - 1;

    } else {

      if (item.producttype === 5 && currentProductTypeCount[5] >= 1) {
        toast.error('Road test course already selected.')
        return;
      }


      currentProductTypeCount[item.producttype] = (currentProductTypeCount[item.producttype] || 0) + 1;
      setSelectedCourses([...selectedCourses, item.packageid]);
    }

    // Update the productTypeCount state
    setProductTypeCount(currentProductTypeCount);
  };


  const isSelected = (item) => selectedCourses.includes(item.packageid);



  const renderCourses = (courses) => (



    courses?.map((item, i) => (
      <Grid
        key={i}
        sx={{
          ...styles.behindTheWheelBox,
          backgroundColor: isSelected(item) ? '#1976d2' : 'white',
          color: isSelected(item) ? 'white' : 'black',
          cursor: 'pointer'
        }}
        onClick={() => handleSelect(item)}
      >
        {console.log('courses item>>>', item)}
        <Grid sx={{ p: '20px' }}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            {
              item.producttype == 5 ?
                <Typography
                  variant="h6"
                  fontWeight="500 !important"
                  sx={{ color: isSelected(item) ? 'white' : 'black', }}
                >
                  {`${item?.packagename}`}
                </Typography>
                :
                <Typography
                  variant="h6"
                  fontWeight="500 !important"
                  sx={{ color: isSelected(item) ? 'white' : 'black', }}
                >
                  {`${item?.duration} hours of ${item?.packagename}`}
                </Typography>
            }
            <Typography sx={{ color: isSelected(item) ? 'white' : 'black', }}>

              {item.producttype == 4 &&
                selectedBTWHour >= 6 ?
                <b>${item?.combopackageamount1}</b> :
                <b>${item.packageamount}</b>
              }


              {/* {courses.productid == 3 &&
                selectedBTWHour < 12 ?
                <b>{item?.combopackageamount1}</b> :
                selectedBTWHour >= 12 ?
                  <b>${item.combopackageamount2}</b> :
                  <b>${item.packageamount}</b>
              } */}




            </Typography>
          </Stack>
        </Grid>
        {
          item?.producttype === 3 &&
          <>

            <Divider sx={{ mb: 1 }} />
            <div style={{ paddingLeft: '10px' }}>


              <Typography variant="caption">
                This defensive class purchased as a Combo may help with Insurance reduction only;
                Teens under the age of 18 should take 30-hr driver’s education class instead to meet Joshua law requirements.
              </Typography>
            </div>

          </>

        }

      </Grid>
    ))
  );







  // checing in api wheather the combo exist or not

  const [finalData, setFinalData] = useState({})
  const fetchcheckPackageisExist = async (array) => {
    try {
      const response = await axios.post(`${base_Url}/checkPackageisExist`, {
        packageid: array
      })
      if (response.data.message === 'success') {
        console.log('response', response?.data?.response[0])
        setFinalData(response?.data?.response)
      } else {
        setFinalData({})
      }
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    const combinedArray = [...[selectedBehindCourse], ...selectedCourses];
    let filteredArray = combinedArray.filter(element => element !== null);
    if (filteredArray.length > 0) {
      console.log('filteredArray    >>>>>>>>>>', filteredArray)
      fetchcheckPackageisExist(filteredArray)
    }
  }, [selectedCourses, selectedBehindCourse])


  console.log('selectedCourses >>>', selectedCourses);



  const [registerStatus, setRegisterStatus] = useState(false)


  const handleAddToCart = async () => {
    const ComboproductArray = finalData.packages?.map((item) => {
      return {
        "productid": item.productid,
        "productname": item.productname,
        "packageid": item.packageid,
        "packagename": item.packagename
      }
    })

    const singleproductArray = finalData.packages?.map((item) => {
      return {
        "productid": item.productid,
        "productname": item.productname,
        "packageid": item.packageid,
        "packagename": item.packagename,
        "totalamount": item?.packageamount
      }
    })

    console.log('selected CCCC>>>>>', finalData)
    let requestedData;
    const tempid = localStorage.getItem('tempId')
    if (finalData?.comboid > 0) {
      requestedData = {
        "tempcustomerid": tempid ? tempid : "",
        "customerid": 0,
        "combo": [
          {
            "comboid": finalData?.comboid,
            "products": ComboproductArray,
            "totalamount": finalData.discountprice
          }
        ],

        "noncombo": []
      }
    } else {
      requestedData = {
        "tempcustomerid": tempid ? tempid : "",
        "customerid": 0,
        "combo": [],
        "noncombo": singleproductArray
      }
    }
    try {
      const response = await axios.post(`${base_Url}/addtocart`, requestedData)
      if (response.data.status) {
        toast.success('Item added ')
        if (!tempid) {
          localStorage.setItem('tempId', response?.data?.response)
        }
      }

    } catch (error) {
      toast.error('unable to add item ')
      console.log(error)
    }
  }


  const handleKnowMoreclick = (type) => {
    const routes = {
      3: '/defensive-driving-remote/2',
      4: '/driving-lesson-joshuas-law',
      5: '/road-testing'
    };
    navigate(routes[type]);
  }



  const my = () => {
    toast.custom((t) => (
      <div>
        This is a custom component <button onClick={() => toast.dismiss(t)}>close</button>
      </div>
    ));
  }


  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [open, setOpen] = useState(false);


  const handleClose = () => {
    if (dontShowAgain) {
      sessionStorage.setItem("showDialog", "false");
    } else {
      sessionStorage.setItem("showDialog", "true");
    }
    setOpen(false);
  };

  const handleCheckboxChange = (event) => {
    // event.preventDefault()
    setDontShowAgain(event.target.checked);
  };


  const AnimatedDialog = styled(Dialog)({
    "& .MuiPaper-root": {
      animation: `$slideIn 5s`,
      "@keyframes slideIn": {
        "0%": {
          transform: "translateX(-100%)",
        },
        "100%": {
          transform: "translateX(0)",
        },
      },
    },
  });



  const [activeSection, setActiveSection] = useState(null); // State to track the active section

  useEffect(() => {
    // Check the stored value when the component mounts
    const showDialog = sessionStorage.getItem("showDialog");

    if (showDialog === null) {
      // Set default value if not set yet
      sessionStorage.setItem("showDialog", "true");

    } else if (showDialog === "true") {
      if (activeSection) {
        if (selectedBTWHour == 2 || selectedBTWHour == 4 || selectedBTWHour == 8 || selectedBTWHour == 10) {
          setOpen(true);
        }
      }
    }
  }, [selectedBTWHour, activeSection]);





  // test code 

  useEffect(() => {
    // Function to handle scrolling and detect section in view
    const handleScroll = () => {
      const section = document.getElementById('section-1');
      const rect = section.getBoundingClientRect();

      // Check if section is in the viewport
      if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
        setActiveSection('section-1'); // Update state when section is fully in view
      } else {
        setActiveSection(null); // Reset state when section is out of view
      }
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);




  return (
    <>


      {/* <div>
        <div style={{ height: '100vh' }}>Scroll Down</div>

        <div
          id="section-1"
          style={{
            height: '500px',
            backgroundColor: activeSection === 'section-1' ? 'lightblue' : 'lightgray',
          }}
        >
          Section 1 (Scroll into view)
        </div>

        <div style={{ height: '100vh' }}>More Content</div>
      </div> */}


      <Toaster />
      <Box sx={{ pt: "60px", pb: "20px", background: "#fff" }}>
        <Container maxWidth="lg">
          <Grid
            container
            spacing={2}
            sx={{
              borderBottom: "1px solid #DADADA",
              pb: "20px",
              "@media (max-width: 600px)": { padding: "20px" },
            }}
          >
            <Typography variant="h4" sx={{ py: 2 }}>
              Behind-the-Wheel Training Packages
            </Typography>
          </Grid>
          <div id="section-1"

          >

            <Grid container spacing={2} sx={{ pt: "40px" }} >

              <Grid item md={4.5} sx={styles.tabMainBox}>
                <Typography variant="h6" sx={{ margin: "20px 0px 20px" }}>
                  Gear Up for Your Driver's License!
                </Typography>

                <Typography sx={{ mb: 3 }}>
                  Select Hours - Pick Your Time Slots
                </Typography>



                <Box sx={styles.scroolbarLocation}>

                  {BehindTheWheel.map((items, index) => (


                    <Stack
                      key={index}
                      onClick={() => handleTabClick(items)}
                      spacing={2}
                      direction="row"
                      sx={{
                        cursor: 'pointer',
                        mb: 2,
                        p: 1,
                        border: selectedBehindCourse === items.packageid && '1px solid #0063D3',
                        borderRadius: selectedBehindCourse === items.packageid && '10px',
                        background: selectedBehindCourse === items.packageid && 'linear-gradient(180deg, #DAEBFF, #ECF5FF)',
                      }}

                    >
                      <Typography>
                        <FiberManualRecordIcon sx={styles.dotIcon} /> &nbsp;
                        {items.packagename}
                      </Typography>

                      <Typography sx={{ color: "gray !important" }}>
                        <b style={styles.tabAmount}>
                          ${items.packageamount}
                        </b>
                      </Typography>
                    </Stack>

                  ))}
                </Box>



              </Grid>

              <Grid item md={3.5} sx={styles.chooseCombo}>
                <Typography
                  variant="h6"
                  sx={{ margin: "20px 0px 6px", textAlign: "center" }}
                >
                  Choose Combo Deals
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    textAlign: "center",
                    color: "var(--primary)",
                    fontWeight: "500 !important",
                  }}
                >
                  Behind the wheel
                </Typography>
                <Typography
                  variant="h3"
                  sx={{ textAlign: "center", fontWeight: "600 !important" }}
                >
                  +
                </Typography>


                <Grid
                  sx={{
                    display: "flex",
                    gap: "23px;",
                    flexDirection: "column",
                  }}
                >
                  {/* {renderCourses(Dui)} */}

                  {/* {renderCourses(driversEducation)} */}

                  {driversEducation?.map((item, i) => (
                    <Grid
                      key={i}
                      sx={{
                        ...styles.behindTheWheelBox,
                        backgroundColor: isSelected(item) ? '#1976d2' : 'white',
                        color: isSelected(item) ? 'white' : 'black',
                        cursor: 'pointer'
                      }}
                      onClick={() => handleSelect(item)}
                    >
                      {console.log('courses item>>>', item)}
                      <Grid sx={{ p: '20px' }}>
                        <Stack direction='row' justifyContent='space-between' alignItems='center'>
                          {
                            item.producttype == 5 ?
                              <Typography
                                variant="h6"
                                fontWeight="500 !important"
                                sx={{ color: isSelected(item) ? 'white' : 'black', }}
                              >
                                {`${item?.packagename}`}
                              </Typography>
                              :
                              <Typography
                                variant="h6"
                                fontWeight="500 !important"
                                sx={{ color: isSelected(item) ? 'white' : 'black', }}
                              >
                                {`${item?.duration} hours of ${item?.packagename}`}
                              </Typography>
                          }
                          {/* <Typography sx={{ color: isSelected(item) ? 'white' : 'black', }}>

                          {item.producttype == 4 &&
                            selectedBTWHour >= 6 ?
                            <b>${item?.combopackageamount1}</b> :
                            <b>${item.packageamount}</b>
                          }
                        </Typography> */}


                          <Typography sx={{ color: isSelected(item) ? 'white' : 'black' }}>
                            <Stack direction="column">
                              {item.producttype == 4 && selectedBTWHour >= 6 ? (
                                <>
                                  <Typography component="span">
                                    <b>${item.combopackageamount1}</b>
                                  </Typography>
                                  <Typography component="span" sx={{ textDecoration: 'line-through', color: 'black !important' }}>
                                    ${item.packageamount}
                                  </Typography>
                                </>
                              ) : (
                                <Typography component="span">
                                  <b>${item.packageamount}</b>
                                </Typography>
                              )}
                            </Stack>
                          </Typography>


                        </Stack>
                      </Grid>
                      {
                        item?.producttype === 3 &&
                        <>

                          <Divider sx={{ mb: 1 }} />
                          <div style={{ paddingLeft: '10px' }}>


                            <Typography variant="caption">
                              This defensive class purchased as a Package  may help with Insurance reduction only;
                              Teens under the age of 18 should take a 30-hr Driver’s Education class instead to meet Joshua law requirements.
                            </Typography>
                          </div>

                        </>

                      }

                    </Grid>
                  ))}

                  {/* <Typography
                  variant="h3"
                  sx={{ textAlign: "center", fontWeight: "600 !important" }}
                >
                  +
                </Typography> */}


                  {/* {renderCourses(roadTesting)} */}

                  {roadTesting?.map((item, i) => (
                    <Grid
                      key={i}
                      sx={{
                        ...styles.behindTheWheelBox,
                        backgroundColor: isSelected(item) ? '#1976d2' : 'white',
                        color: isSelected(item) ? 'white' : 'black',
                        cursor: 'pointer'
                      }}
                      onClick={() => handleSelect(item)}
                    >
                      {console.log('courses item>>>', item)}
                      <Grid sx={{ p: '20px' }}>
                        <Stack direction='row' justifyContent='space-between' alignItems='center'>
                          {
                            item.producttype == 5 ?
                              <Typography
                                variant="h6"
                                fontWeight="500 !important"
                                sx={{ color: isSelected(item) ? 'white' : 'black', }}
                              >
                                {`${item?.packagename}`}
                              </Typography>
                              :
                              <Typography
                                variant="h6"
                                fontWeight="500 !important"
                                sx={{ color: isSelected(item) ? 'white' : 'black', }}
                              >
                                {`${item?.duration} hours of ${item?.packagename}`}
                              </Typography>
                          }
                          {/* <Typography sx={{ color: isSelected(item) ? 'white' : 'black', }}>
                          {
                            selectedBTWHour ?
                              <b>${item?.combopackageamount1}</b> :
                              <b>${item.packageamount}</b>
                          }
                        </Typography> */}

                          <Typography sx={{ color: isSelected(item) ? 'white' : 'black' }}>
                            <Stack direction="column">
                              {selectedBTWHour ? (
                                <>
                                  <Typography component="span">
                                    <b>${item.combopackageamount1}</b>
                                  </Typography>
                                  <Typography component="span" sx={{ textDecoration: 'line-through' }}>
                                    ${item.packageamount}
                                  </Typography>
                                </>
                              ) : (
                                <Typography component="span">
                                  <b>${item.packageamount}</b>
                                </Typography>
                              )}
                            </Stack>
                          </Typography>

                        </Stack>
                      </Grid>
                      {
                        item?.producttype === 3 &&
                        <>

                          <Divider sx={{ mb: 1 }} />
                          <div style={{ paddingLeft: '10px' }}>


                            <Typography variant="caption">
                              This defensive class purchased as a Package  may help with Insurance reduction only;
                              Teens under the age of 18 should take a 30-hr Driver’s Education class instead to meet Joshua law requirements.
                            </Typography>
                          </div>

                        </>

                      }

                    </Grid>
                  ))}

                  {/* <Typography
                  variant="h3"
                  sx={{ textAlign: "center", fontWeight: "600 !important" }}
                >
                  +
                </Typography> */}

                  {/* {renderCourses(defensive)} */}

                  {
                    defensive?.map((item, i) => (
                      <Grid
                        key={i}
                        sx={{
                          ...styles.behindTheWheelBox,
                          backgroundColor: isSelected(item) ? '#1976d2' : 'white',
                          color: isSelected(item) ? 'white' : 'black',
                          cursor: 'pointer'
                        }}
                        onClick={() => handleSelect(item)}
                      >
                        {console.log('courses item>>>', item)}
                        <Grid sx={{ p: '20px' }}>
                          <Stack direction='row' justifyContent='space-between' alignItems='center'>
                            {
                              item.producttype == 5 ?
                                <Typography
                                  variant="h6"
                                  fontWeight="500 !important"
                                  sx={{ color: isSelected(item) ? 'white' : 'black', }}
                                >
                                  {`${item?.packagename}`}
                                </Typography>
                                :
                                <Typography
                                  variant="h6"
                                  fontWeight="500 !important"
                                  sx={{ color: isSelected(item) ? 'white' : 'black', }}
                                >
                                  {`${item?.duration} hours of ${item?.packagename}`}
                                </Typography>
                            }
                            {/* <Typography sx={{ color: isSelected(item) ? 'white' : 'black', }}>
                            {!_.isNil(selectedBTWHour) &&
                              selectedBTWHour < 12 ?
                              <b>${item?.combopackageamount1}</b> :
                              selectedBTWHour >= 12 ?
                                <b>${item.combopackageamount2}</b> :

                                <b>${item.packageamount}</b>
                            }
                          </Typography> */}


                            <Typography sx={{ color: isSelected(item) ? 'white' : 'black' }}>
                              <Stack direction="column">
                                {!_.isNil(selectedBTWHour) && selectedBTWHour < 12 ? (
                                  <>
                                    <Typography component="span">
                                      <b>${item.combopackageamount1}</b>
                                    </Typography>
                                    <Typography component="span" sx={{ textDecoration: 'line-through' }}>
                                      ${item.packageamount}
                                    </Typography>
                                  </>
                                ) : selectedBTWHour >= 12 ? (
                                  <>
                                    <Typography component="span">
                                      <b>${item.combopackageamount2}</b>
                                    </Typography>
                                    <Typography component="span" sx={{ textDecoration: 'line-through' }}>
                                      ${item.packageamount}
                                    </Typography>
                                  </>
                                ) : (
                                  <Typography component="span">
                                    <b>${item.packageamount}</b>
                                  </Typography>
                                )}
                              </Stack>
                            </Typography>



                          </Stack>
                        </Grid>
                        {
                          item?.producttype === 3 &&
                          <>

                            <Divider sx={{ mb: 1 }} />
                            <div style={{ paddingLeft: '10px' }}>

                              <Stack spacing={1}>

                                <Typography variant="caption">
                                  <FiberManualRecordIcon sx={styles.dotIcon} /> &nbsp;
                                  This defensive class purchased as a Package  may help with Insurance reduction only.
                                </Typography>

                                <Typography variant="caption">
                                  <FiberManualRecordIcon sx={styles.dotIcon} /> &nbsp;
                                  Teens under the age of 18 should take a 30-hr Driver’s Education class instead to meet Joshua law requirements
                                </Typography>


                              </Stack>
                            </div>

                          </>

                        }

                      </Grid>
                    ))
                  }

                </Grid>



              </Grid>
              {
                !_.isEmpty(finalData) ?
                  <Grid item xs={12} md={4}>
                    <Card>
                      {
                        <CardMedia
                          component="img"
                          image={finalData?.packages[0]?.image}
                          alt="Behind the Wheel"
                          height={200}
                          sx={{ objectFit: 'cover' }}
                        />
                      }

                      <CardContent>

                        {finalData?.packages?.map((item, index) => (
                          <React.Fragment key={index}>
                            <Typography sx={{ color: 'black !important', fontSize: '20px', fontWeight: 400 }}>
                              {item.packagename}
                            </Typography>
                          </React.Fragment>
                        ))}


                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                          <Typography>
                            {
                              finalData?.comboid == 0 ?
                                <span style={{ fontWeight: "700", fontSize: "30px", color: "black" }}>

                                  ${finalData?.packages?.reduce((total, item) => total + (item.packageamount || 0), 0)}

                                </span> :
                                <span style={{ fontWeight: "700", fontSize: "30px", color: "black" }}>
                                  ${finalData?.discountprice}
                                </span>
                            }
                            &nbsp;&nbsp;

                            {
                              finalData?.comboid == 0 ?
                                <span style={{ fontWeight: "700", fontSize: "30px", color: "black" }}>

                                </span> :
                                <span
                                  style={{
                                    textDecoration: "line-through",
                                    fontSize: "20px",
                                    color: 'gray'
                                  }}
                                >
                                  ${finalData?.finalcost}
                                </span>
                            }

                          </Typography>
                        </Stack>

                        {
                          finalData?.packages?.length === 1 && [3, 4, 5].includes(finalData.packages[0].producttype) ? (
                            <Button variant="contained" onClick={() => handleKnowMoreclick(finalData.packages[0].producttype)}>Know more</Button>
                          ) : (
                            <Stack direction={'row'} spacing={2}>
                              {/* <Button
                              variant="outlined"
                              onClick={() => handleAddToCart()}
                              sx={{ mt: 2 }}
                            >
                              ADD TO CART
                            </Button> */}
                              <Button
                                variant="contained"
                                sx={{ mt: 2 }}
                                onClick={() => setRegisterStatus(true)}
                              >
                                BUY NOW
                              </Button>
                            </Stack>
                          )
                        }






                      </CardContent>
                    </Card>

                    <Card sx={{ mt: 4 }}>
                      <Typography sx={{ p: 2 }}>
                        <b style={{ fontWeight: '900 !important' }}>Please note : </b>Teens Age 16 & 17 are required to take the 30-hour Driver's Education class in order to take their Driver's License.
                      </Typography>
                    </Card>
                  </Grid>


                  :

                  <Grid item xs={12} md={4}>
                    <Card>

                      <CardMedia
                        component="img"
                        image={'https://webnox.blr1.digitaloceanspaces.com/studentportal/Course-not-Found.jpg'}
                        alt="Behind the Wheel"
                        height={200}
                        sx={{ objectFit: 'cover' }}
                      />


                      <CardContent>


                        <React.Fragment >
                          <Typography sx={{ color: 'black !important', fontSize: '20px', fontWeight: 400 }}>
                            Selected Combo is not available.
                          </Typography>
                          <Typography sx={{ color: 'black !important', fontSize: '20px', fontWeight: 400 }}>
                            please choose another combo...
                          </Typography>

                        </React.Fragment>



                      </CardContent>
                    </Card>

                  </Grid>
              }
              <Register
                openStatus={registerStatus}
                setRegisterStatus={setRegisterStatus}
                finalData={finalData}
              />




            </Grid>

          </div>
        </Container >

        {/* <button onClick={() => setOpen(true)}>Click me</button> */}
      </Box >


      {/* <AnimatedDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="custom-dialog-title"
        TransitionComponent={Transition}
        transitionDuration={{ enter: 200, exit: 200 }}
        keepMounted
      >
        <DialogTitle id="custom-dialog-title">Special Offer</DialogTitle>
        <DialogContent>
          {
            selectedBTWHour == 2 || selectedBTWHour == 4 ?
              <Typography>
                Unlock Your Full Potential! Upgrade to 6 hours of BTW classes and grab
                Drivers Education for just $95 (save $29!). More hours, more confidence,
                and bigger savings – get started today!
              </Typography>

              : selectedBTWHour == 8 || selectedBTWHour == 10 ? (
                <Typography>
                  Maximize your learning! Upgrade to 12 hours of BTW classes and get Defensive Driving absolutely FREE!
                  Don’t miss out on this exclusive offer!
                </Typography>
              )

                : null
          }

        </DialogContent>

        <DialogActions>
          <FormControlLabel
            control={
              <Checkbox
                checked={dontShowAgain}
                onChange={(event) => handleCheckboxChange(event)}
              />
            }
            label="Do not show again"
          />
          <Button variant="contained" color="error" onClick={handleClose}>Close</Button>
        </DialogActions>
      </AnimatedDialog> */}

      <UpsellingDialog

        open={open}
        handleClose={handleClose}
        selectedBTWHour={selectedBTWHour}
        dontShowAgain={dontShowAgain}
        handleCheckboxChange={handleCheckboxChange}
      />

    </>
  );
}

export default DlPackages;
