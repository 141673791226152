import { Box, CardMedia, Container, Grid, Typography } from '@mui/material'
import React from 'react'

import ProminentBg from '../../assets/images/driving-lesson/drvng_lessons_prominent_bg.png';
import RlFeaturesRhtImg from '../../assets/images/roadTesting/RlFeaturesRhtImg.png';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import pickup from '../../assets/images/driving-lesson/pickup.gif';


const styles = {
    featureBg: {
        flexGrow: 1, background: `url(${ProminentBg}) no-repeat top center`, backgroundSize: '100% 100%', padding: '60px 0px 60px',
        '@media (max-width: 900px)': {
            padding: '50px 0px 50px 16px',
        }
    },
    featureGif: {
        width: '70px', height: '70px',
    },
    featureBoxCon: {
        background: '#D7E7FF', display: 'flex', gap: '10px', padding: '24px 20px', borderRadius: '6px',
        '&:hover': { background: '#fff', },
        '@media (max-width: 600px)': {
            flexDirection: 'column',
        },

    },

}

const featureBox = [
    {
        featureBoxGif: pickup,
        tittle: "Insurance Premium Discount",
        description: "Get attractive discounts (minimum 10%) on insurance premium that is valid for 3 years",

    },
    {
        featureBoxGif: pickup,
        tittle: "Flexible Scheduling",
        description: "Our courses are available at day and night schedules throughout the week, ensuring you can complete the training at your convenience.",

    },
    {
        featureBoxGif: pickup,
        tittle: "Expert Instructors",
        description: "Learn from experienced, certified instructors who are dedicated to providing personalized guidance and support throughout the course.",

    },
]

const DdrFeatures = () => {
    return (
        <>

            <Box sx={styles.featureBg} >
                <Container >
                    <Grid container spacing={2}>

                        <Grid item md={6}>
                            {/* <Typography sx={{color:'#fff !important', marginBottom:'10px',}}>Features</Typography> */}
                            <Typography variant='h4' sx={{ color: 'var(--yellow) !important', }}>Stay Alert, Skip the Fines!</Typography>
                            <Typography variant='h4' sx={{ color: '#fff !important', mt: '10px', }}>Learn Defensive Driving Skills</Typography>
                        </Grid>

                        <Grid item md={6} sx={{ display: 'flex', justifyContent: 'end', }}>
                            <Grid sx={{ position: 'relative' }}>
                                {/* <img src={RlFeaturesRhtImg} style={{maxWidth:'100%',}}/>
                        <PlayArrowIcon className='playbut'  sx={{position:'absolute',}} /> */}

                                <video controls width="90%" height={'90%'}>
                                    <source src={"https://webnox.blr1.digitaloceanspaces.com/1ACT_WEBSITE_VIDEOS/Defensive.mp4"}
                                        type="video/mp4" />
                                    Sorry, your browser doesn't support embedded videos.
                                </video>
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid container spacing={2} sx={{ marginTop: '40px', display: 'flex', gap: '14px', }}>

                        {featureBox.map((featureBoxItem, index) => (
                            <Grid item md={3.9} key={index} sx={{ padding: '0px !important', display: 'grid' }}>
                                <Grid sx={styles.featureBoxCon}>

                                    <CardMedia component="img" alt="program Image" src={featureBoxItem.featureBoxGif} sx={styles.featureGif} />
                                    <Grid>
                                        <Typography variant="h2" sx={{ marginBottom: '10px' }}>{featureBoxItem.tittle}</Typography>
                                        <Typography component="f15w500">{featureBoxItem.description}</Typography>
                                    </Grid>

                                </Grid>
                            </Grid>
                        ))}

                    </Grid>

                </Container>
            </Box>


        </>
    )
}

export default DdrFeatures
